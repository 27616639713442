import { watchImmediate } from '@vueuse/core'
import dayjs from 'dayjs'
import { insertInvisibleElementWithIdForPendo } from '~/lib/utils'

export function usePendoTriggers() {
  const { data: orgData } = storeToRefs(useProfileStore())

  watchImmediate(
    () => orgData.value?.organisation?.credit_facility_assignment_date,
    (newVal) => {
      if (!newVal) return
      const diffInDays = dayjs().diff(newVal, 'day')
      if (diffInDays >= 7) {
        // Triggers a feedback prompt if a customer’s credit facility was assigned at least 7 days ago
        insertInvisibleElementWithIdForPendo('7dayFeedback')
      }
    },
  )
}
