<script setup lang="ts">
import { useMutation } from '@tanstack/vue-query'
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'
import { vuelidateErrorMessage } from '~/lib/utils'

import BaseTelInput from '@/components/Base/BasePhoneInput.vue'
import { toast } from '~/components/ui/toast'

const rules = {
  phone_number: {
    required: helpers.withMessage('Phone number is required', required),
  },
}

const form = ref({
  phone_number: '',
})

const { $api } = useNuxtApp()
const profileStore = useProfileStore()
const fetchingProfile = ref(false)

const { mutate: mutateAddUserPhoneNumber, isPending: isSubmitting } =
  useMutation({
    mutationFn: $api.core.me.addPhoneNumberToLoggedInUser,
    onSuccess() {
      toast({
        title: 'Phone number added successfully',
        description: 'Your phone number has been added to your profile',
      })
      fetchingProfile.value = true
      profileStore.userProfile()
    },
  })

const v$ = useVuelidate(rules, form)

function handleFormSubmit() {
  v$.value.$touch()
  if (v$.value.$invalid) return

  mutateAddUserPhoneNumber(form.value.phone_number)
}
</script>

<template>
  <form @submit.prevent="handleFormSubmit">
    <BaseTelInput
      v-model="form.phone_number"
      label="Phone number"
      :disabled="isSubmitting"
      :has-error="v$.phone_number.$error"
      :error-message="vuelidateErrorMessage(v$.phone_number.$errors)"
    />
    <Button
      :is-loading="isSubmitting || fetchingProfile"
      :disabled="isSubmitting || fetchingProfile"
      class="mt-6 w-full"
      >Submit</Button
    >
  </form>
</template>
